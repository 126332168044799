import script from "./ReportPreference.vue?vue&type=script&setup=true&lang=ts"
export * from "./ReportPreference.vue?vue&type=script&setup=true&lang=ts"

import "./ReportPreference.vue?vue&type=style&index=0&id=4f2117a0&lang=scss"

const __exports__ = script;

export default __exports__
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QItem,QItemSection,QItemLabel,QToggle,QSelect});
